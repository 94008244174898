import React from 'react'
import './style.css'
import { connect } from 'react-redux';
import {
    playAction,
    seekPercentageAction,
    seekAction
} from '../../../modules/playback'

import {
    toggleTopicsVisible,
    logUserAction
} from '../../../modules/ui';


class Seekbar extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            mainTimelineTopics: []
        }

        this.trigger = false;
        this.triggerInterval = null;

        this.startTrigger = this.startTrigger.bind(this);

        this.mouseMoveFunction = this.mouseMoveFunction.bind(this);
        this.touchMoveFunction = this.touchMoveFunction.bind(this);
    }

    componentDidMount() {
        this.populateTopics();
    }

    populateTopics() {
        if (this.props.currentRunningOrder) {
            this.setState({mainTimelineTopics: this.props.currentRunningOrder.getPopulatedTopics()});
        }
    }

    getTotalDuration() {
        let totalDuration = 0;
        this.state.topics.forEach(topic=>{
            totalDuration = totalDuration + topic.duration;
        });
        return totalDuration;
    }

    getDivs() {
        let toReturn = [];
        this.state.mainTimelineTopics.forEach((topic)=>{
            toReturn.push(
                <div key={topic._id} className={`seekbar-topic ${this.props.activeTopic && this.props.activeTopic._id === topic._id ? 'active': ''}`} style={{
                    height: `calc(${100/this.state.mainTimelineTopics.length}% - ${(10 * (this.state.mainTimelineTopics.length -1 )) / (this.state.mainTimelineTopics.length )}px)`
                }} id={topic._id}
                onClick={(e)=>{
                    this.topicClicked(e)
                    if (this.props.topicsVisible) {
                        this.props.logUserAction('SEEKBAR_TOPIC', {custom_var_8: topic._id, custom_var_9: topic.title});
                        window.noSleep.enable();
                        // window.smpPlayer.player.play();
                        this.props.seekAction(this.props.currentRunningOrder.taiToFloat(topic.beginning_of_topic), topic._id);

                    }
                }}
                onMouseMove={this.mouseMoveFunction}
                >
                    <div
                    className="seekbar-topic-background" onClick={()=>{
                        this.props.logUserAction('SEEKBAR_OPEN', {custom_var_8: 'sidebar'});
                        this.props.toggleTopicsVisible(true);}
                    }></div>
                        <div style={{
                            opacity:`${this.props.topicsVisible? '1':'0'}`,
                            transform: `${this.props.topicsVisible? 'translateX(0%)':'translateX(200%)'}`
                            }} className="seekLabel noselect">

                            <div key={`${topic._id}-nug`} className="nug"></div>
                            <b key={`${topic._id}-title`} >{topic.title}</b>
                        </div>
                </div>
            )
        })
        return toReturn;
    }
    topicClicked(e) {
        this.pointerElement.style.transform = `translateY(${e.target.parentNode.offsetTop}px)`;
        clearTimeout(this.triggerInterval);
        this.triggered = false;
    }

    mouseMoveFunction (e) {
        e.stopPropagation();
        if (this.triggered ) {
            this.pointerElement.style.transform = `translateY(${e.clientY - e.target.parentNode.parentNode.offsetTop}px)`;
        }
    }

    touchMoveFunction (e) {
        if (this.triggered && (e.nativeEvent.targetTouches[0].clientY > 50 && (e.nativeEvent.targetTouches[0].clientY < (e.nativeEvent.targetTouches[0].target.parentNode.offsetHeight + 50)) )) {
            this.pointerElement.style.marginTop = `${e.nativeEvent.targetTouches[0].clientY - 60}px`;
            this.props.seekPercentageAction((e.nativeEvent.targetTouches[0].clientY - 50)/e.nativeEvent.targetTouches[0].target.parentNode.offsetHeight);
        }
    }

    startTrigger(originalEvent) {
        originalEvent.persist();

        let triggered = () => {
            this.triggered = true;
        }
        this.triggerInterval = setTimeout(triggered, 400);
        originalEvent.target.addEventListener('mouseup', this.mouseUpFunction);
        originalEvent.target.addEventListener('touchend', this.mouseUpFunction);
    }

    mouseUpFunction(e) {
        e.target.removeEventListener('mouseup', this.mouseUpFunction);
        e.target.removeEventListener('touchend', this.mouseUpFunction);
        clearTimeout(this.triggerInterval);
        this.triggered = false;
    };

    render() {
        return (<div className="seekbar"
        onMouseDown={this.startTrigger}
        onMouseLeave={this.mouseUpFunction.bind(this)}
        >
            <div ref={this.setPointerRef} className="seekPointer"></div>
            <div className={`seekLabelBackground ${this.props.topicsVisible?'labelsVisible':''}`}></div>
            <div className={`blanker ${this.props.topicsVisible?'opacityOne':''}`}></div>
            <div className="centreLine"></div>
            {this.getDivs()}
        </div>)
    }

    setPointerRef = element => {
        this.pointerElement = element;
    };
}


const mapStateToProps = state => ({
    topicsVisible: state.ui.topicsVisible,
    activeTopic: state.runningOrder.active.topic,
    currentRunningOrder: state.runningOrder.currentRunningOrder
});

const mapDispatchToProps = dispatch => ({
playAction: () => dispatch(playAction()),
seekPercentageAction: (percentage) => dispatch(seekPercentageAction(percentage)),
seekAction: (timestamp, segmentID) => dispatch(seekAction(timestamp, segmentID)),
toggleTopicsVisible: (bool) => dispatch(toggleTopicsVisible(bool)),
logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Seekbar);

//export default Timeline
