import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';
import {
    playAction,
    pauseAction,
    seekAction
} from '../../../modules/playback';
import {
    toggleTopicsVisible,
    toggleShareEntryModalVisible,
    logUserAction
} from '../../../modules/ui';
import { ExternalLink, Duration } from '@bbc/gel-react-webpack/iconography'
import 'gel-typography/dist/gel-typography.css'
import Transcription from '../transcription'
import Contributor from './contributor';
import moment from 'moment';

class Timeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visiblePane: null,
        }
        this.scrollInterval = null;
        this.systemScrolled = false;
        this.animatedScroll = false;
        this.lastAnimatedScroll = false;

        this.contributorsDisplayed = [];
        this.jquery = null;

        // if cookie warning has already been shown, display shareEntryModal
        if (localStorage.cookieWarning) this.checkShareLink();
        else this.loadjQuery();
    }

    async loadjQuery() {
        return new Promise((done)=>{
            if (this.jquery) done();
            else {
                window.requirejs(['jquery-1.9'],($) => {
                    this.jquery = $;
                    window.bleep = $;
                    done();
                });
            }
        });
    }

    async checkShareLink() {
        await this.loadjQuery();
        const uuid = this.props.location.search.replace('?','').replace('#','');
        if (uuid.length === 6) {
            this.props.logUserAction('SHARE_LINK_OPEN', {custom_var_8: uuid});
            //go through running order and look for id given, then seek to it.
            this.seekToInitialSegment = setInterval(()=>{
                if (this.props.readyToPlay && this.state.visiblePane && this.drawnDivsOnce) {

                    let segmentToScrollTo = this.jquery(`#segment-${uuid}`);
                    let topicToScrollTo = this.jquery(`#topic-${uuid}`);
                    if (segmentToScrollTo[0]) {
                        //found segment
                        this.systemScrolled = true;
                        this.jquery('#visible-pane').animate({
                            scrollTop: segmentToScrollTo[0].offsetTop - this.jquery('#visible-pane')[0].offsetHeight/2 + segmentToScrollTo[0].offsetHeight/2
                        },250,()=>{this.systemScrolled = false;});

                        document.querySelector(`#segment-${uuid}`).click()
                        //playit.
                    } else if (topicToScrollTo[0]) {
                        //found topic
                        this.systemScrolled = true;
                        this.jquery('#visible-pane').animate({
                            scrollTop: topicToScrollTo[0].offsetTop - this.jquery('#visible-pane')[0].offsetHeight/2 + topicToScrollTo[0].offsetHeight/2
                        },250,()=>{this.systemScrolled = false;});

                        document.querySelector(`#topic-${uuid}`).click()
                    }
                    this.props.toggleShareEntryModalVisible();
                    clearInterval(this.seekToInitialSegment);
                }
            },200)
        }
    }

    componentDidMount() {
        this.setState(() => {
            return {
                visiblePane: document.getElementById("visible-pane")
            };
        });
    }

    setActive (id, active) {
        const element = document.getElementById(id);
        if (element) {
            if (active) element.classList.add("active");
            else element.classList.remove("active");
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.cookieModalVisible &&
            !nextProps.cookieModalVisible) {
            this.checkShareLink();
        }
        if (this.props.pendingCommand &&
            this.props.pendingCommand.type === "TRANSPORT" &&
            this.props.pendingCommand.command === "SEEK")
        {
            this.scrollEvents = 0;
        }
        if (!this.props.active.segment &&
            nextProps.active.segment &&
            nextProps.active.topic &&
            nextProps.active.contributor)
        {
            this.setActive(`segment-${nextProps.active.segment._id}`, true);
            this.setActive(`topic-${nextProps.active.topic._id}`, true);
            this.setActive(`contributor-${nextProps.active.contributor}`, true);
            this.scrollToSegment(nextProps.active.segment._id, nextProps.active.topic._id);
        }
        if (this.props.active.segment &&
            nextProps.active.segment &&
            nextProps.active.topic)
        {
            if (nextProps.active.segment._id !== this.props.active.segment._id) {
                this.setActive(`segment-${this.props.active.segment._id}`, false);
                this.setActive(`segment-${nextProps.active.segment._id}`, true);
                this.scrollToSegment(nextProps.active.segment._id, nextProps.active.topic._id);
            }
        }
        if (this.props.active.topic &&
            nextProps.active.topic)
        {
            if (nextProps.active.topic._id !== this.props.active.topic._id) {
                this.setActive(`topic-${this.props.active.topic._id}`, false);
                this.setActive(`topic-${nextProps.active.topic._id}`, true);
            }
        }
        if (this.props.active.contributor &&
            nextProps.active.contributor)
        {
            if (nextProps.active.contributor !== this.props.active.contributor) {
                this.setActive(`contributor-${this.props.active.contributor}`, false);
                this.setActive(`contributor-${nextProps.active.contributor}`, true);
            }
        }
        if (this.props.transcriptionVisible !== nextProps.transcriptionVisible) return true;
        if (this.props.fetching !== nextProps.fetching) return true;

        return false;
    }

    scrollToSegment(segmentID, topicID) {
        if (this.scrollEvents > 2) {
            return;
        }
        if (!this.jquery) {
            this.loadjQuery();
            return;
        }
        let visiblePane = this.jquery('#visible-pane');
        let segmentToScrollTo = this.jquery(`#segment-${segmentID}`)[0];
        let topicToScrollTo = this.jquery(`#topic-${topicID}`)[0];
        if (segmentToScrollTo) {
            this.systemScrolled = true;
            visiblePane.animate({
                scrollTop: segmentToScrollTo.offsetTop - visiblePane[0].offsetHeight/2 + segmentToScrollTo.offsetHeight/2
            },250,()=>{this.systemScrolled = false;});

        } else if (topicToScrollTo) {
            this.systemScrolled = true;
            visiblePane.animate({
                scrollTop: topicToScrollTo.nextSibling.offsetTop - visiblePane[0].offsetHeight/2 + topicToScrollTo.nextSibling.offsetHeight/2
            },250,()=>{this.systemScrolled = false;});

        }
    }

    findSegmentType(editorial_data) {
        if (editorial_data.external_link && editorial_data.external_link.embeddable) {
            return 'embedded_link'
        } else if (editorial_data.external_link) {
            return 'external_link';
        } else if (editorial_data.image) {
            return 'image';
        }
        return 'transcript';
    }

    scrollToActiveSegment() {
        if (this.props.active.segment) {
            this.scrollToSegment(this.props.active.segment._id);
        }
    }

    getTopicAndSegmentDivs() {
        this.contributorsDisplayed = [];
        var containmentDOMRect = this.state.visiblePane ? this.state.visiblePane : null;
        let lastContributorIds = null;
        let toReturn = [];
        this.props.active.version.populatedSubTopics.forEach(topic => {
            if (containmentDOMRect) {
                toReturn.push(
                        <div key={`topic-${topic._id}`} onClick={()=>{
                            this.props.logUserAction('TIMELINE_TOPIC',{custom_var_8: topic._id, custom_var_9: topic.title});
                            window.noSleep.enable();
                            // window.smpPlayer.player.play();
                            this.props.seekAction(this.props.currentRunningOrder.taiToFloat(topic.beginning_of_topic), topic._id);
                        }} id={`topic-${topic._id}`} className={`timeline-tile topic ${this.props.active.topic && (this.props.active.topic._id === topic._id? 'active':'')}`}>
                        <div className="timeline-tile_linethrough"></div>
                            <div className="timeline-tile-flex-container">
                            <div className="topicTitle_background">
                                <h5 className="topicTitle gel-double-pica">{topic.title}</h5>
                            </div>

                            </div>
                        </div>
                );

                //unedit this when you work out topic synopsisessesess
                if (topic.editorial_data) {
                    toReturn.push(
                        <div key={`topic-synopsis-${topic._id}`} onClick={()=>{
                            this.props.logUserAction('TIMELINE_TOPIC_SYNOPSIS',{custom_var_8: topic._id, custom_var_9: topic.title});
                            // window.smpPlayer.player.play();
                            this.props.seekAction(this.props.currentRunningOrder.taiToFloat(topic.beginning_of_topic), topic._id);
                        }} id={`topic_synopsis-${topic._id}`} className={`timeline-tile topicSynopsis active`}>
                            <div className="timeline-tile-flex-container">
                                <div className="topicTitle_background">
                                {
                                    topic.editorial_data.synopses && topic.editorial_data.synopses.short_synopsis &&
                                    <p>{topic.editorial_data.synopses.short_synopsis}</p>
                                }

                                </div>

                            </div>
                        </div>
                    );
                }

                topic.segments.forEach(segment => {
                        let lastContributorCardId = null;

                        if (segment.editorial_data.contributors ) {
                            segment.editorial_data.contributors.forEach(contributorId =>{

                                if (!this.contributorsDisplayed.includes(contributorId)) {

                                    toReturn.push(
                                            <div key={contributorId} id={`contributor-${contributorId}`} className={`timeline-tile contributor ${this.props.active.contributor && (this.props.active.contributor.includes(contributorId))? 'active':''}`}>
                                                <div className="timeline-tile-flex-container">
                                                {
                                                    this.props.active.version.editorial_data.contributors.filter(contributor =>  segment.editorial_data.contributors.includes(contributor._id)) &&
                                                    <Contributor contributor={this.props.active.version.editorial_data.contributors.filter(contributor =>  segment.editorial_data.contributors.includes(contributor._id))[0]} />
                                                }
                                                </div>
                                            </div>
                                    )
                                    this.contributorsDisplayed.push(contributorId);
                                    lastContributorCardId = contributorId;
                                }
                            })
                        }
                        if (segment.editorial_data.embeddable_url || segment.editorial_data.external_link || segment.editorial_data.image || this.props.transcriptionVisible) {
                            toReturn.push(
                                    <div key={segment._id} onClick={()=>{
                                        this.props.logUserAction('TIMELINE_SEGMENT', {custom_var_8: segment._id, custom_var_9: this.findSegmentType(segment.editorial_data)});
                                        if (this.props.active.segment && (segment._id === this.props.active.segment._id)) return;
                                        if (!this.props.playing) {
                                            window.noSleep.enable();
                                            // window.smpPlayer.player.play();
                                        }
                                        this.scrollEvents = 0;
                                        this.props.seekAction(this.props.currentRunningOrder.taiToFloat(topic.beginning_of_topic) + this.props.currentRunningOrder.taiToFloat(segment.in_time), segment._id);
                                    }} id={`segment-${segment._id}`} className={`timeline-tile segment ${segment.editorial_data.external_link && segment.editorial_data.external_link.embeddable?'iframe':''} ${(this.props.active.segment) && (segment._id === this.props.active.segment._id)?'active':''}`}>
                                        <div className="timeline-tile-flex-container">
                                            {
                                                this.props.debug &&
                                                <div>
                                                    {segment.in_time} - {segment.out_time}
                                                </div>
                                            }
                                            { segment.editorial_data.external_link && segment.editorial_data.external_link.embeddable &&

                                                    <iframe title={segment.editorial_data.external_link.url} className="timeline-tile_fullIframe" src={segment.editorial_data.external_link.url} />


                                            }
                                            { segment.editorial_data.external_link && segment.editorial_data.external_link.caption && segment.editorial_data.external_link.embeddable &&
                                                <div className="image-caption">
                                                    <p>{segment.editorial_data.external_link.caption}</p>
                                                </div>
                                            }
                                            { segment.editorial_data.image &&
                                                <div className="image-caption">
                                                    <img alt={segment.editorial_data.image.caption} className="timeline-tile_fullscreenImage" src={`${segment.editorial_data.image.url.includes('http')? segment.editorial_data.image.url: '/images/thumbnails/' + segment.editorial_data.image.url}`} />
                                                    <div>
                                                        <span>{segment.editorial_data.image.caption}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.props.transcriptionVisible &&
                                                <Transcription showAvatar={((JSON.stringify(lastContributorIds) === JSON.stringify(segment.editorial_data.contributors)) || (segment.editorial_data.contributors.includes(lastContributorCardId))) ? false:true } contributors={segment.editorial_data.contributors} transcript={segment.editorial_data.transcript} />
                                            }
                                            { segment.editorial_data.external_link && !segment.editorial_data.external_link.embeddable &&
                                                <div className="timeline-tile_external_link">
                                                  <a rel="noopener noreferrer" href={segment.editorial_data.external_link.url} target={segment.editorial_data.external_link.url.substring(0,4)=='http'?'_blank':'_self'} onClick={()=>{
                                                      this.props.logUserAction('TIMELINE_LINK',{custom_var_8: segment._id, custom_var_9: segment.editorial_data.external_link.url});
                                                  }}>
                                                      <div className="external_link_icon">
                                                          <ExternalLink />
                                                      </div>

                                                      <div className="external_link_caption_container">
                                                          <div>
                                                              <span>{segment.editorial_data.external_link.caption}</span>
                                                          </div>
                                                      </div>
                                                  </a>
                                                </div>
                                            }
                                        </div>

                                    </div>
                            )
                        }
                        lastContributorIds = segment.editorial_data.contributors;
                });
            }
        });
        this.drawnDivsOnce = true;
        return toReturn;
    }

    getIntroSlide() {
        return (
            <div onScroll={(e)=>{
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
            }} className="timeline-tile intro ">
                    <div>
                            <div>
                                <img alt={this.props.active.version.editorial_data.titles.primary_title} className="timeline-tile_primaryImage" src={`https://ichef.bbci.co.uk/images/ic/1200x675/${this.props.currentRunningOrder.getVersionEditorialData().images.primary.pid}.jpg`} />
                            </div>
                            <h1 className="gel-trafalgar">{this.props.active.version.editorial_data.titles.primary_title}</h1>
                            <div className="lineBreak"></div>
                            <p className="gel-body-copy">{this.props.active.version.editorial_data.synopses.short_synopsis}</p>
                            <h4><Duration gels={['1/1', '1/2@m', '1/4@l']}/> {moment.duration(this.props.currentRunningOrder.getVersionDuration(true) , 'seconds').humanize()}</h4>
                    </div>

            </div>
        );
    }

    onTimelineScroll(e) {
        if (e.nativeEvent.target.scrollTop < 300 && !this.props.topicsVisible) {
            this.props.toggleTopicsVisible(true);
        } else if (e.nativeEvent.target.scrollTop > 300 && this.props.topicsVisible){
            this.props.toggleTopicsVisible(false);
        }
        this.lastAnimatedScroll = this.animatedScroll;
        this.animatedScroll = this.jquery('#visible-pane').is(':animated');
        if (this.scrollInterval) {
            clearTimeout(this.scrollInterval);
        }
        if (this.systemScrolled) return;
        this.scrollEvents = this.scrollEvents+1;
        if (this.scrollEvents > 1) {
            this.scrollInterval = setTimeout(()=>{
                this.scrollEvents = 0;
                if (this.props.playing) this.scrollToActiveSegment();
            }, 5000);
        }
    }


    render() {
        if (this.props.active.version) {
            return (
                <div onScroll={this.onTimelineScroll.bind(this)} className="timeline-container" id="visible-pane">
                    {this.getIntroSlide()}
                    {this.getTopicAndSegmentDivs()}
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    readyToPlay: state.playback.readyToPlay,
    cookieModalVisible: state.ui.cookieModalVisible,
    pendingCommand: state.playback.pendingCommand,
    active: state.runningOrder.active,
    transcriptionVisible: state.ui.transcriptionVisible,
    fetching: state.runningOrder.fetching,
    currentRunningOrder: state.runningOrder.currentRunningOrder,
    playing: state.playback.playing,
    debug: state.ui.debug,
    topicsVisible: state.ui.topicsVisible
});

const mapDispatchToProps = dispatch => ({
    playAction: () => dispatch(playAction()),
    pausection: () => dispatch(pauseAction()),
    seekAction: (timestamp, segmentID) => dispatch(seekAction(timestamp, segmentID)),
    toggleTopicsVisible: (bool) => dispatch(toggleTopicsVisible(bool)),
    toggleShareEntryModalVisible: (bool) => dispatch(toggleShareEntryModalVisible(bool)),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
