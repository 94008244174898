const TAITimestamp = require('@bbc/tai-timestamp');

class RunningOrder {
    constructor(runningOrder) {
        this.runningOrder = runningOrder;
        this.populatedSegments = [];
        this.populateAllSegments();
    }

    getVersionPopulatedSegments() {
        return this.populatedSegments;
    }

    getVersionServiceId() {
        return this.runningOrder.service_id;
    }

    getVersionBrandId() {
        return this.runningOrder.brand_id;
    }

    getVersionVersionId() {
        return this.runningOrder.version_id;
    }

    getVersionEpisodeId() {
        return this.runningOrder.episode_id;
    }

    getVersionId() {
        return this.runningOrder._id;
    }

    getVersionEditorialData(){ 
        return this.getAllSegmentsFromSegmentEvent(this.runningOrder.segment_event)[0].editorial_data;
    }

    getVersionTimeline() {
        return this.runningOrder.segment_event.timeline;
    }

    getVersionContributors() {
        return this.getAllSegmentsFromSegmentEvent(this.runningOrder.segment_event)[0].editorial_data.contributors;
    }

    getVersionTopic() {
        return this.runningOrder.segment_event.topics;
    }

    getVersionTopics() {
        return this.getAllTopicsFromTimeline(this.runningOrder.segment_event.timeline);
    }

    getVersionSourceId() {
        return this.runningOrder.segment_event.segment.source_id;
    }

    getPopulatedTopics() {
        let mainTimelinePopulatedTopics = this.getVersionTopics();
        for (let topicNum = 0; topicNum < this.getVersionTopics().length; topicNum++) {
            mainTimelinePopulatedTopics[topicNum].beginning_of_topic = this.getSegmentEventPlacementsFromTimeline(this.getVersionTimeline())[topicNum].timeline_placement.start;
            mainTimelinePopulatedTopics[topicNum].segments = this.getAllSegmentsFromTimeline(this.getMainTimelineSubTimelines()[topicNum]);
        }
        return mainTimelinePopulatedTopics;
    }

    getSegmentsFromTimeline(timeline) {
        return this.getAllSegmentsFromTimeline(timeline);
    }

    getAllSegmentsFromVersion() {
        return this.getAllSegmentsFromTimeline(this.getVersionTimeline(), true);
    }

    getMainTimelineSubTimelines() {
        return this.getAllTimelines().splice(1);
    }

    getAllTopics() {
        return this.getAllTopicsFromSegmentEvent(this.runningOrder.segment_event);
    }

    getAllSegments() {
        return this.getAllSegmentsFromSegmentEvent(this.runningOrder.segment_event);
    }

    getAllTimelines() {
        return this.getAllTimelinesFromSegmentEvent(this.runningOrder.segment_event);
    }

    getAllTimelinesFromSegmentEvent(segmentEvent) {
        let foundTimelines = [];
        if (segmentEvent) {
            if (segmentEvent.timeline) {
                foundTimelines.push(segmentEvent.timeline);
                if (segmentEvent.timeline.segmentEventPlacements) {
                    segmentEvent.timeline.segmentEventPlacements.forEach(segmentEventPlacement => {
                        if (segmentEventPlacement.segment_event) {
                            foundTimelines.push(...this.getAllTimelinesFromSegmentEvent(segmentEventPlacement.segment_event));
                        }
                    })
                }
            }
            
        }
        return foundTimelines;
    }  

    getAllTopicsFromSegmentEvent(segmentEvent) {
        let topicsFound = [];
        if (segmentEvent) {
            if (segmentEvent.topics) {
                topicsFound.push(...segmentEvent.topics);
            }
            if (segmentEvent.timeline) {
                topicsFound.push(...this.getAllTopicsFromTimeline(segmentEvent.timeline));
            }
        }
        return topicsFound;
    }

    getAllTopicsFromTimeline(timeline) {
        let topicsFound = [];
        if (timeline && timeline.segmentEventPlacements) {
            timeline.segmentEventPlacements.forEach(segmentEventPlacement => {
                if (segmentEventPlacement.segment_event) {
                    topicsFound.push(...this.getAllTopicsFromSegmentEvent(segmentEventPlacement.segment_event));
                }
            });
        }
        return topicsFound;
    }

    getAllSegmentsFromSegmentEvent(segmentEvent) {
        let segmentsFound = [];
        if (segmentEvent) {
             if (segmentEvent.segment) {
                 segmentsFound.push(segmentEvent.segment);
             }
             if (segmentEvent.timeline) {
                segmentsFound.push(...this.getAllSegmentsFromTimeline(segmentEvent.timeline));
             }
        }
        return segmentsFound;
    }

    getAllSegmentsFromTimeline(timeline, recursive = false) {
        let segmentsFound = [];
        if (timeline && timeline.segmentEventPlacements) {
            timeline.segmentEventPlacements.forEach(segmentEventPlacement => {
                if (segmentEventPlacement.segment_event && segmentEventPlacement.segment_event.segment) {
                    segmentsFound.push(segmentEventPlacement.segment_event.segment);
                } 
                if (segmentEventPlacement.segment_event.timeline && recursive) {
                    segmentsFound.push(...this.getAllSegmentsFromTimeline(segmentEventPlacement.segment_event.timeline));
                }
            });
         }
         return segmentsFound;
    }

    getSegmentEventPlacementsFromTimeline(timeline) {
        let segmentEventPlacementsFound = [];
        if (timeline && timeline.segmentEventPlacements) {
            timeline.segmentEventPlacements.forEach(segmentEventPlacement => {
                    segmentEventPlacementsFound.push(segmentEventPlacement);
            });
         }
         return segmentEventPlacementsFound;
    }

    getVersionDuration(human) {
        let versionSegment = this.getAllSegments().filter(segment => segment.segment_type === 'version');
        if (versionSegment) {
            let startTai = new TAITimestamp(versionSegment[0].in_time);
            let endTai = new TAITimestamp(versionSegment[0].out_time);
            return endTai.subtract(startTai).toFloat();
        }
    }

    getSegmentDuration(segment) {
        if (segment) {
            let startTai = new TAITimestamp(segment.in_time);
            let endTai = new TAITimestamp(segment.out_time);
            return endTai.subtract(startTai).toFloat();
        }
    }

    populateAllSegments() {
        let index = 0;
        this.getMainTimelineSubTimelines().forEach(timeline => {
            let timeLineStart = this.taiToFloat(this.getSegmentEventPlacementsFromTimeline(this.getVersionTimeline())[index].timeline_placement.start);
            this.getAllSegmentsFromTimeline(timeline, true).forEach(segment => {
                segment.parent_timeline_start = timeLineStart;
                this.populatedSegments.push(segment);
            });
            index++;
        })
    }

    // old wat to get current segment, looping through too many arrays to get there
    // getCurrentSegment(currentTimestampSeconds) {
    //     var currentSegment = null;
    //     var index = 0;
    //     this.getMainTimelineSubTimelines().forEach(timeline => {
    //         var timelineSegEvent = this.getSegmentEventPlacementsFromTimeline(this.getVersionTimeline())[index];
    //         var timeLineStart = this.taiToFloat(this.getSegmentEventPlacementsFromTimeline(this.getVersionTimeline())[index].timeline_placement.start);
    //         console.log(timeLineStart);
    //         this.getAllSegmentsFromTimeline(timeline, true).forEach(segment => {
    //             if (
    //             (currentTimestampSeconds >= (timeLineStart + this.taiToFloat(segment.in_time) - 0.3)) // the 0.3 second offset is here to accomodate for the ios/hls accuracy bug
    //             && 
    //             (currentTimestampSeconds < (timeLineStart + this.taiToFloat(segment.out_time)))
    //             ) {
                    
    //                 currentSegment = segment;
    //             }
    //         });
    //         index++;
    //     })
    //     return currentSegment;
    // }

    // new way to get curretn segment, only loops through a prepopulated list of segments
    getCurrentSegment(currentTimestampSeconds) {
        var currentSegment = null;
        this.populatedSegments.forEach(segment => {
                if (
                (currentTimestampSeconds >= (segment.parent_timeline_start + this.taiToFloat(segment.in_time) - 0.3)) // the 0.3 second offset is here to accomodate for the ios/hls accuracy bug
                && 
                (currentTimestampSeconds < (segment.parent_timeline_start + this.taiToFloat(segment.out_time)))
                ) {
                    
                    currentSegment = segment;
                }
            });
        return currentSegment;
    }

    getCurrentTopic(currentSegment) {
        if (currentSegment) {
            let topicFound = null;
            this.getVersionTopics().forEach(topic => {
                topic.segments.forEach(segment => {
                    if (segment._id === currentSegment._id) {
                        topicFound = topic;
                    }
                })
            });
            return topicFound;
        } else {
            return null;
        }
    }

    getNextSegment(currentTimestamp) {
        if (this.runningOrder) {
            var segmentsFound = [];
            this.getAllTimelines().forEach(timeline => {
                this.getAllSegmentsFromTimeline(timeline).forEach(segment => {
                    segmentsFound.push(segment);
                });
            });
            var currentIndex = segmentsFound.indexOf(this.getCurrentSegment(currentTimestamp));
            return segmentsFound[currentIndex + 1];
        }
    }

    getPreviousSegment(currentTimestamp) {
        if (this.runningOrder) {
            var segmentsFound = [];
            this.getAllTimelines().forEach(timeline => {
                this.getAllSegmentsFromTimeline(timeline).forEach(segment => {
                    segmentsFound.push(segment);
                });
            });
            var currentIndex = segmentsFound.indexOf(this.getCurrentSegment(currentTimestamp));
            return segmentsFound[currentIndex -1];
        }
    }

    taiToFloat(taiTimestamp) {
        return new TAITimestamp(taiTimestamp).toFloat();
    }

    floatToTai(float) {
        
    }  
}

export default RunningOrder;