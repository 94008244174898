import React, {Component} from 'react'

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.css'

import {
    fetchEpisodes
} from '../../modules/runningOrder'
import RunningOrder from '../../lib/RunningOrder';

class Browse extends Component {

    componentDidMount() {
        if (!this.props.episodes.length) {
            this.props.fetchEpisodes();
        }
        
    }

    getEpisodesLayout() {
        return this.props.episodes.map((rawScript)=> {
            var script = new RunningOrder(rawScript);
            return (
                <div key={script.getVersionVersionId()} className="browse_episode" style={{
                    //backgroundImage: `url(https://ichef.bbci.co.uk/images/ic/1200x675/${script.getVersionEditorialData().images.primary.pid}.jpg)`
                    }}>
                    <Link to={`/${script.getVersionVersionId()}`}>
                    {/* <img className="browse_episode_thumbnail" src={`https://ichef.bbci.co.uk/images/ic/1200x675/${script.getVersionEditorialData().images.primary.pid}.jpg`}/> */}
                    <h3 className="browse_episode_display_title">{script.getVersionEditorialData().titles.display_title}</h3>
                    </Link>
                </div>
            )
        });
    }

    render() {
        return (
            <div>
                {this.getEpisodesLayout()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    episodes: state.runningOrder.episodes
});
  
const mapDispatchToProps = dispatch => ({
    fetchEpisodes: () => dispatch((fetchEpisodes()))
})

export default connect(mapStateToProps, mapDispatchToProps)(Browse);