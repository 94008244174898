import { createStore, applyMiddleware, compose } from 'redux'
import { createMiddleware } from 'redux-beacon'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from './modules'
import { echoTarget } from './lib/analytics'
import { eventsMap } from './trackedEvents'

export const history = createHistory()

const initialState = {}
const enhancers = []
const beacon = createMiddleware(eventsMap, echoTarget)
const middleware = [thunk, beacon, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)
