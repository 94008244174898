const initialState = {
    theme: {
        primary: '#0c3767',
        secondary: '#00ff00',
        text: '#0000ff',
        types: {
            interview: '#ff0000',
            drama: '#00ff00',
            other: '#0f0f0f'
        }
    },
    transcriptionVisible: true,
    shareModalVisible: false,
    shareEntryModalVisible: false,
    helpModalVisible: false,
    cookieModalVisible: true,
    topicsVisible: true,
    contributorVisible: false,
    snackbarMessage: "",
    debug: false
  }
  



export const toggleTranscriptionVisible = () => dispatch => {
    dispatch({
        type: 'TOGGLE_TRANSCRIPTION',
    });
}

export const toggleTopicsVisible = (bool) => dispatch => {
    dispatch({
        type: 'TOGGLE_TOPICS',
        bool:  bool
    });
}

export const toggleShareModalVisible = () => dispatch => {
    dispatch({
        type: 'TOGGLE_SHARE_MODAL',
    });
}

export const toggleShareEntryModalVisible = () => dispatch => {
    dispatch({
        type: 'TOGGLE_SHARE_ENTRY_MODAL',
    });
}

export const toggleHelpModalVisible = () => dispatch => {
    dispatch({
        type: 'TOGGLE_HELP_MODAL',
    });
}

export const showCookieModal = () => dispatch => {
    dispatch({
        type: 'SHOW_COOKIE_MODAL',
    });
}

export const hideCookieModal = () => dispatch => {
    dispatch({
        type: 'HIDE_COOKIE_MODAL',
    });
}

export const updateContributorVisible = (contributor) => dispatch => {
    dispatch({
        type: 'UPDATE_CONTRIBUTOR_VISIBLE',
        contributor
    });
}

export const showSnackbar = (message) => dispatch => {
    dispatch({
        type: 'SHOW_SNACKBAR',
        message
    });
}

export const hideSnackbar = () => dispatch => {
    dispatch({
        type: 'HIDE_SNACKBAR'
    });
}

export const logUserAction = (action_type, payload) => dispatch => {
    dispatch({
        type: 'USER_ACTION',
        action_type,
        payload
    });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_TRANSCRIPTION':
            return Object.assign({}, state, {
            transcriptionVisible: !state.transcriptionVisible
            });
        case 'TOGGLE_TOPICS':
            return Object.assign({}, state, {
                topicsVisible: action.bool
            });
        case 'TOGGLE_SHARE_MODAL':
            return Object.assign({}, state, {
                shareModalVisible: !state.shareModalVisible
            });
        case 'TOGGLE_SHARE_ENTRY_MODAL':
            return Object.assign({}, state, {
                shareEntryModalVisible: !state.shareEntryModalVisible
            });
        case 'TOGGLE_HELP_MODAL':
            return Object.assign({}, state, {
                helpModalVisible: !state.helpModalVisible
            });
        case 'SHOW_COOKIE_MODAL':
            return Object.assign({}, state, {
                cookieModalVisible: true
            });
        case 'HIDE_COOKIE_MODAL':
            return Object.assign({}, state, {
                cookieModalVisible: false
            });
        case 'TOGGLE_DEBUG':
            return Object.assign({}, state, {
                debug: !state.debug
            });
        case 'UPDATE_CONTRIBUTOR_VISIBLE':
            return Object.assign({}, state, {
                contributorVisible: action.contributor
            });
        case 'SHOW_SNACKBAR':
            return Object.assign({}, state, {
                snackbarMessage: action.message
            });
        case 'HIDE_SNACKBAR':
            return Object.assign({}, state, {
                snackbarMessage: ""
            });
        default:
            return state
    }
}
    
       