import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';
import {
    playAction,
    pauseAction,
    resetPendingCommandAction,
    updateCurrentTimestampAction,
    updatePlayingStateAction,
    updateBufferingStateAction,
    loadPidAction,
    loadUrlAction,
    initialisedAction,
    readyToPlayAction
} from '../../../modules/playback';
import {
    setFetchingError
} from '../../../modules/runningOrder';


class SmpWrapper extends Component {

    constructor(props) {
        super(props);
        this.toSeekToTimestamp = null;
        this.firstPlay = false;
        this.sourceOpen = this.sourceOpen.bind(this);
        this.loadAudio = this.loadAudio.bind(this);
        this.audioLoaded = this.audioLoaded.bind(this);
        this.audioLoadFailed = this.audioLoadFailed.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.seek = this.seek.bind(this);
        this.playAudio = this.playAudio.bind(this);
        this.stopAudio = this.stopAudio.bind(this);
        this.getCurrentTime = this.getCurrentTime.bind(this);
        this.updateTime = this.updateTime.bind(this);

        this.buffer = null;
        this.source = null;
        this.pausedAt = 0;
        this.startedAt = 0;
        this.playing = false;
        this.timeUpdate = null;

        if ('AudioContext' in window || 'webkitAudioContext' in window) {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            this.audioContext = new AudioContext();
        } else {
            this.props.setFetchingError('Sorry, your browser is not supported. We recommend you use Chrome for this experiment.');
        }

        this.props.initialisedAction()
        this.updatePlayingStateAction(false);
    }

    sourceOpen(url) {
      fetch(url)
      .then(response => response.arrayBuffer())
      .then(this.loadAudio);
    }

    loadAudio(data) {
        this.audioContext.decodeAudioData(data, this.audioLoaded, this.audioLoadFailed);
    }

    audioLoaded(buffer) {
        this.buffer = buffer;
        this.props.updateBufferingStateAction(false);
        this.props.readyToPlayAction();
    }

    audioLoadFailed() {
        this.props.setFetchingError('Failed to load audio. Please refresh and try again.');
    }

    updateTime() {
        var currentTime = this.getCurrentTime();
        if (this.playing) this.updateCurrentTimestampAction(currentTime);
        if (this.source && !this.playing) {
            this.updatePlayingStateAction(true);
        }
    }

    play() {
        this.playAudio();
        this.updatePlayingStateAction(true);
        if (!this.firstPlay) {
            this.firstPlay = true;
            this.toSeekToTimestamp = null;
        }
        this.updatePlayingStateAction(true);
    }

    playAudio(time) {
        if (!time) {
            if (this.pausedAt) {
                time = this.pausedAt
            } else {
                time = 0;
            }
        }
        this.source = this.audioContext.createBufferSource();
        this.source.connect(this.audioContext.destination);
        this.source.buffer = this.buffer;
        this.source.start(0, time);
        this.startedAt = this.audioContext.currentTime - time;
        this.pausedAt = 0;
        this.playing = true;
        this.timeUpdate = setInterval(this.updateTime, 200);
    }

    pause() {
        var elapsed = this.audioContext.currentTime - this.startedAt;
        this.stopAudio();
        this.pausedAt = elapsed;
        this.updatePlayingStateAction(false);
    }

    seek() {
        if (this.playing) this.stopAudio();
        this.playAudio(this.props.pendingCommand.timestamp);
        this.updatePlayingStateAction(true);
    }

    stopAudio() {
        if (this.timeUpdate) clearInterval(this.timeUpdate);
        if (this.source) {
            this.source.disconnect();
            this.source.stop(0);
            this.source = null;
        }
        this.pausedAt = 0;
        this.startedAt = 0;
        this.playing = false;
    }

    getCurrentTime() {
        if(this.pausedAt) {
            return this.pausedAt;
        }
        if(this.startedAt) {
            return this.audioContext.currentTime - this.startedAt;
        }
        return 0;
    };

    componentDidMount() {
        this.props.loadUrlAction(this.props.currentRunningOrder.getVersionSourceId());
    }

    componentDidUpdate() {
        if (this.props.pendingCommand) {
            if (this.props.pendingCommand.type === "TRANSPORT") {
                switch(this.props.pendingCommand.command) {
                    case 'PLAY':
                        this.play();
                        break;
                    case 'PAUSE':
                        this.pause();
                        break;
                    case 'SEEK':
                        this.seek();
                        break;
                    case 'SEEK_PERCENTAGE':
                        // console.log(`Seek% ${this.props.pendingCommand.percentage}`);
                        // this.stopAudio();
                        // this.playAudio(this.source.buffer * this.props.pendingCommand.percentage);
                        break;
                    default:
                }
            } else if (this.props.pendingCommand.type === "PLAYLIST") {
                switch(this.props.pendingCommand.command) {
                    case 'LOAD_URL':
                        if (!this.audioContext) break;
                        this.sourceOpen(this.props.pendingCommand.url);
                        break;
                    default:
                }
            }
            this.resetPendingCommandAction();
        }
    }

    render() {
     return (
       <div className="player"></div>
        //  <audio
        //     id="audioPlayer"
        //     autoPlay={false}
        //     preload="auto"
        //     onTimeUpdate={() => {
        //         var currentTime = document.getElementById('audioPlayer').currentTime;
        //         if (this.props.playing) this.updateCurrentTimestampAction(currentTime);
        //         if (this.source && !this.props.playing) {
        //             this.updatePlayingStateAction(true);
        //         }
        //         //this.updatePlayingStateAction(true);
        //     }}
        //     onPlay={() => {
        //         if (!this.firstPlay) {
        //             this.firstPlay = true;
        //             this.player.currentTime = this.toSeekToTimestamp;
        //             this.toSeekToTimestamp = null;
        //             this.updatePlayingStateAction(true);
        //         }
        //         this.updatePlayingStateAction(true);
        //         // this.props.updateBufferingStateAction(false);
        //     }}
        //     onPause={() => {
        //         this.updatePlayingStateAction(false);
        //     }}
        //     // onWaiting={() => {
        //     //     this.props.updateBufferingStateAction(true);
        //     //     this.updatePlayingStateAction(false);
        //     // }}
        //     onLoadedData={() => {
        //         this.props.readyToPlayAction();
        //     }}
        //     // onCanPlayThrough={()=>{
        //     //     this.props.updateBufferingStateAction(false);
        //     // }}
        //     // onMediaItemChanged={() => {
        //     //     this.props.updateBufferingStateAction(true)
        //     //     this.updatePlayingStateAction(false);
        //     // }}
        //     ref={player => {
        //     this.player = player;
        //     window.smpPlayer = {};
        //     window.smpPlayer.player = player;
        //     }}
        // />
       // </div>
     );
    }

    updateCurrentTimestampAction = (timestamp) => {
        this.props.updateCurrentTimestampAction(timestamp);
    }

    updatePlayingStateAction = (bool) => {
        this.props.updatePlayingStateAction(bool);
    }

    resetPendingCommandAction = (event) => {
        this.props.resetPendingCommandAction();
       }
   }
   
   
   
   const mapStateToProps = state => ({
     pendingCommand: state.playback.pendingCommand,
     playing: state.playback.playing,
     currentRunningOrder: state.runningOrder.currentRunningOrder,
     activeVersion: state.runningOrder.active.version
    });
   
    const mapDispatchToProps = dispatch => ({
     playAction: () => dispatch(playAction()),
     pauseAction: () => dispatch(pauseAction()),
     resetPendingCommandAction: () => dispatch(resetPendingCommandAction()),
     updateCurrentTimestampAction: (timestamp) => dispatch(updateCurrentTimestampAction(timestamp)),
     updatePlayingStateAction: (bool) => dispatch(updatePlayingStateAction(bool)),
     updateBufferingStateAction: (bool) => dispatch(updateBufferingStateAction(bool)),
     loadPidAction: (pid) => dispatch(loadPidAction(pid)),
     loadUrlAction: (pid) => dispatch(loadUrlAction(pid)),
     initialisedAction: () => dispatch(initialisedAction()),
     readyToPlayAction: () => dispatch(readyToPlayAction()),
     setFetchingError: (error) => dispatch(setFetchingError(error))
    })

    export default connect(mapStateToProps, mapDispatchToProps)(SmpWrapper);