import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';
import Modal from 'react-modal';

import {
    toggleHelpModalVisible,
    logUserAction
} from '../../../modules/ui'

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    borderRadius:0
  }
};

document.addEventListener('contextmenu', event => event.preventDefault());
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.8)';
Modal.defaultStyles.overlay.zIndex = 99999999999999999999999999;


class HelpModal extends Component {

    constructor(props) {
        super(props);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    afterOpenModal() {
        this.props.logUserAction('HELP_MODAL_OPEN');
    }

    closeModal() {
        this.props.toggleHelpModalVisible();
        this.props.logUserAction('HELP_MODAL_CLOSE');
    }

    render() {
        return (
            <Modal
                isOpen={this.props.helpModalVisible}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Help"
                >
                <div className="helpModal">
                    <div className="helpText">
                      <h3>User guide</h3>
                      <p>This is a prototype podcast player that displays additional information, images and links throughout the programme.</p>
                      <p><strong>Playback</strong>: Click the play button in the bottom-right to play or pause.</p>
                      <p><strong>Topics</strong>: Click the sidebar on the right to reveal the topic names, then click on the topic you want to listen to.</p>
                      <p><strong>Transcripts</strong>: Click on a sentence to start listening from that point. You can turn transcripts off using the button in the top-right.</p>
                      <p><strong>Contributors</strong>: Click on the name of a contributor to see their profile.</p>
                      <p><strong>Links</strong>: Click on a link to open it in a new tab.</p>
                      <p><strong>Share</strong>: Click the share button in the top-right to share the current programme, topic or sentence on social media.</p>
                    </div>
                    <div className="closeDiv">
                        <button className="genericButton shareButton closeButton" onClick={this.closeModal}>Close</button>
                    </div>
                </div>

            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    helpModalVisible: state.ui.helpModalVisible
});

const mapDispatchToProps = dispatch => ({
    toggleHelpModalVisible: ()=> dispatch(toggleHelpModalVisible()),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpModal);
