import React from 'react'
import { Route } from 'react-router-dom'
// import Home from '../home'
// import About from '../about'
import Play from '../play'
import Browse from '../browse'
import './style.css'

const App = () => (
  <div className="mainApp">
    <main>
      {/* <Play /> */}
      <Route exact path="/" component={Play} />
      // <Route path="/:version_id/" component={Play} />
      // <Route path="/:version_id/:uuid" component={Play} />
    </main>
  </div>
)

export default App
