import { combineReducers } from 'redux'
import counter from './counter'
import playback from './playback'
import ui from './ui'
import runningOrder from './runningOrder'

export default combineReducers({
  counter,
  playback,
  ui,
  runningOrder
})
