import { logUserAction, logSystemAction } from './lib/analytics'

export const eventsMap = {
    'USER_ACTION': logUserAction,
    'QUEUE_COMMAND': logSystemAction,
    'INITIALISED': logSystemAction,
    'READY_TO_PLAY': logSystemAction,
    //'RESET_PENDING_COMMAND': logSystemAction,
    //'UPDATE_CURRENT_TIMESTAMP': logSystemAction,
    //'UPDATE_PLAYING_STATE': logSystemAction,
    //'UPDATE_BUFFERING_STATE': logSystemAction,
    'UPDATE_FETCHING': logSystemAction,
    'UPDATE_FETCHING_EPISODES': logSystemAction,
    'SET_EPISODES': logSystemAction,
    'ADD_SCRIPT': logSystemAction,
    //'SET_CURRENT_RUNNING_ORDER': logSystemAction
}
