import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';

import {
    updateContributorVisible,
    logUserAction
} from '../../../modules/ui';

class Transcription extends Component {

    constructor(props) {
        super(props);
        this.contributors = this.props.currentRunningOrder.getVersionContributors();
    }

    getContributorCard() {
        let foundContributor = this.contributors.filter(contributor =>  this.props.contributors.includes(contributor._id));
        if (foundContributor) {
            let toReturn = [];
            foundContributor.forEach(contributor =>{
                toReturn.push(
                    <div className="transcription_contributor_card" key={contributor._id}>
                        { this.props.showAvatar &&
                        <div className="transcription_contributor_card_thumbnail_container" onClick={()=>{
                            this.props.logUserAction('CONTRIBUTOR_MODAL_OPEN',{custom_var_8: contributor._id, custom_var_9: contributor.name, custom_var_10: 'thumbnail'});
                            this.props.updateContributorVisible(contributor);
                        }}>

                                <img alt={contributor.name} className="transcription_contributor_card_thumbnail" src={`/images/thumbnails/${contributor.thumbnail?contributor.thumbnail:'man-user.svg'}`} />

                        </div>
                        }
                    </div>
                );
            });
            return toReturn;
        }
        return null;
    }
    
    render() {
            return ( 
                <div className="transcriptionContainer">
                    {this.getContributorCard()}
                    <div className="transcriptionArea">
                        <p className="gel-body-copy">{this.props.transcript}</p>
                    </div>
                    
                </div>
            );
    }
}


const mapStateToProps = state => ({
    currentRunningOrder: state.runningOrder.currentRunningOrder
});

 const mapDispatchToProps = dispatch => ({
  updateContributorVisible: (contributor) => dispatch(updateContributorVisible(contributor)),
  logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
 })

export default connect(mapStateToProps, mapDispatchToProps)(Transcription);
