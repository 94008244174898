import Axios from 'axios';

import RunningOrder from '../lib/RunningOrder';

const initialState = {
    currentRunningOrder: null,
    fetching: false,
    fetching_error: null,
    active: {
        version: null,
        topic: null,
        segment: null,
        contributors: null
    },
    episodes:[]
}
  
export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_FETCHING':
            return Object.assign({}, state, {
                fetching: action.payload
            });
        case 'FETCHING_ERROR':
            return Object.assign({}, state, {
                fetching_error: action.payload
            });
        case 'SET_CURRENT_RUNNING_ORDER':
            let newRunningOrder = new RunningOrder(action.payload);
            window.newRunningOrder = newRunningOrder;
            return Object.assign({}, state, {
                currentRunningOrder: newRunningOrder,
                active: {
                    version: {
                        id: newRunningOrder.getVersionId(),
                        version_id: newRunningOrder.getVersionVersionId(),
                        service_id: newRunningOrder.getVersionServiceId(),
                        brand_id: newRunningOrder.getVersionBrandId(),
                        episode_id: newRunningOrder.getVersionEpisodeId(),
                        editorial_data: newRunningOrder.getVersionEditorialData(),
                        timeline: newRunningOrder.getVersionTimeline(),
                        contributors: newRunningOrder.getVersionContributors,
                        topic: newRunningOrder.getVersionTopic(),
                        subTopics: newRunningOrder.getVersionTopics(),
                        populatedSubTopics: newRunningOrder.getPopulatedTopics(),
                        segments: newRunningOrder.getAllSegmentsFromVersion()
                    },
                    topic: state.active.topic,
                    segment: state.active.segment,
                    contributors: state.active.contributors,
                }
            });
        case 'UPDATE_CURRENT_TIMESTAMP': 
            if (state.currentRunningOrder) {
                const currentSegment = state.currentRunningOrder.getCurrentSegment(action.payload);
                return Object.assign({}, state, {
                    active: {
                        version: state.active.version,
                        contributor: currentSegment ? currentSegment.editorial_data.contributors : null,
                        topic: state.currentRunningOrder.getCurrentTopic(currentSegment),
                        segment: currentSegment
                    }
                });
            } else {
                return Object.assign({}, state);
            }
        case 'SET_EPISODES': 
            return Object.assign({}, state, {
                episodes: action.payload
            });

        case 'ADD_SCRIPT': 
            return Object.assign({}, state, {
                episodes: state.episodes = [...state.episodes, action.payload]
            });
        default:
            return state
    }
}

//actions
export const setFetching = (bool) => dispatch => {
    dispatch({
        type: 'UPDATE_FETCHING',
        payload: bool
    });
}

export const setFetchingError = (error) => dispatch => {
    dispatch({
        type: 'FETCHING_ERROR',
        payload: error
    });
}

export const setFetchingEpisodes = (bool) => dispatch => {
    dispatch({
        type: 'UPDATE_FETCHING_EPISODES',
        payload: bool
    });
}

export const setEpisodes = (episodes) => dispatch => {
    dispatch({
        type: 'SET_EPISODES',
        payload: episodes
    });
}

export const addScript = (script) => dispatch => {
    dispatch({
        type: 'ADD_SCRIPT',
        payload: script
    });
}

export const setCurrentRunningOrder = (ro) => dispatch => {
    dispatch({
        type: 'SET_CURRENT_RUNNING_ORDER',
        payload: ro
    });
}

export const fetchEpisodes = () =>{
    return async dispatch => {
        dispatch(setFetchingEpisodes(true));
        let episodesResponse = await Axios.get(`/episodes/episodes.json`);
        episodesResponse.data.forEach(async (id) =>{
            let roResponse = await Axios.get(`/episodes/${id}.json`);
            dispatch(addScript(roResponse.data));
            dispatch(setFetchingEpisodes(false));
        });
      };
}

export const fetchRunningOrder = (id) => {
    return async dispatch => {
      dispatch(setFetching(true));
      try {
          let roResponse = await Axios.get(`/episodes/${id}.json`);
          dispatch(setCurrentRunningOrder(roResponse.data));
          dispatch(setFetching(false));
      } catch(error) {
          if (error.response && error.response.status)
            dispatch(setFetchingError(`${error.response.status} error encountered. Please contact the investigator.`));
          else
            dispatch(setFetchingError(`Could not find data. Please contact the investigator.`));
      }
    };
}
