import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';

import Modal from 'react-modal';
import { Twitter, Facebook, Whatsapp } from 'react-social-sharing'
import Clipboard from 'react-clipboard.js';
import { Link } from '@bbc/gel-react-webpack/iconography';

import {
    toggleShareModalVisible,
    showSnackbar,
    hideSnackbar,
    logUserAction
} from '../../../modules/ui'

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    borderRadius:0
  }
};

document.addEventListener('contextmenu', event => event.preventDefault());
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.8)';
Modal.defaultStyles.overlay.zIndex = 899898989;


class ShareModal extends Component {
    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showSnackbar = this.showSnackbar.bind(this);
        this.state = {
            shareSelection: 'episode'
        }
    }

    // shouldComponentUpdate() {
    //     if (!this.props.activeSegment || !this.props.activeTopic && (this.state.shareSelection !== 'episode')) {
    //         this.setState({shareSelection:'episode'});
    //     }
    // }

    getShareLink() {
        //if end of episode (so no active topic or segment), revert share selection back to episode

        let host = window.location.host;
        if (window.location.port === "80") host = window.location.hostname;
        switch (this.state.shareSelection) {
            case 'episode':
                return `http://${host}/`
            case 'topic':
                return `http://${host}/${this.props.activeTopic ? `?${this.props.activeTopic._id}`:''}`
            case 'segment':
                return `http://${host}/${this.props.activeSegment? `?${this.props.activeSegment._id}`:''}`
            default:
                return `http://${host}/`
        }
    }

    openModal() {
        this.props.toggleShareModalVisible();
    }

    afterOpenModal() {
        this.props.logUserAction('SHARE_MODAL_OPEN');
    }

    closeModal() {
        this.props.toggleShareModalVisible();
        this.props.logUserAction('SHARE_MODAL_CLOSE');
    }

    showSnackbar() {
        this.props.showSnackbar('Link copied!');
    }

    render() {

        return (
            <Modal
                isOpen={this.props.shareModalVisible}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                <div className="shareModal">
                    <div className="shareOptions">
                      <p>Please choose where you would like to share playback from:</p>
                      <div className="shareButtons">
                          <div >
                              <button onClick={()=>{
                                  this.setState({shareSelection:'episode'});
                              }} className={`genericButton shareButton ${this.state.shareSelection === 'episode'?'active':''}`}>Start of episode</button>
                          </div>
                          { !this.props.activeTopic &&
                          <div>
                              <span>You can also share specific parts of the podcast once you start playback</span>
                          </div>
                          }
                          { this.props.activeTopic &&
                          <div>
                              <button onClick={()=>{
                                  this.setState({shareSelection:'topic'});
                              }} className={`genericButton shareButton ${this.state.shareSelection === 'topic'?'active':''}`}>Current Topic ({this.props.activeTopic.title})</button>
                          </div>
                          }
                          { this.props.activeSegment && this.props.transcriptionVisible &&
                          <div>
                              <button onClick={()=>{
                                  this.setState({shareSelection:'segment'});
                              }} className={`genericButton shareButton ${this.state.shareSelection === 'segment'?'active':''}`}>Current Highlighted Segment</button>
                          </div>
                          }
                      </div>
                      <div>
                          <span>via</span>
                      </div>
                      { this.props.activeVersion &&
                          <div>
                              <Clipboard button-href="#" component="a" className="copyButton" data-clipboard-text={this.getShareLink()} onSuccess={()=>{
                                  this.props.logUserAction('SHARE', {custom_var_8: 'copy_link', custom_var_9: this.state.shareSelection, custom_var_10: this.getShareLink()});
                                  this.showSnackbar();
                              }}>
                                  <div><Link /> Copy link</div>
                              </Clipboard>
                              <Twitter message={"Try this experimental podcast player on @BBCTaster that helps to cut through the fake news and get to the facts."} link={this.getShareLink()} onClick={()=>{
                                  this.props.logUserAction('SHARE', {custom_var_8: 'twitter', custom_var_9: this.state.shareSelection, custom_var_10: this.getShareLink()});
                              }} />
                              <Facebook link={this.getShareLink()} onClick={()=>{
                                  this.props.logUserAction('SHARE', {custom_var_8: 'facebook', custom_var_9: this.state.shareSelection, custom_var_10: this.getShareLink()});
                              }} />
                              <Whatsapp message={"Try this experimental BBC podcast player that helps to cut through the fake news and get to the facts."} link={this.getShareLink()} target='_self' onClick={()=>{
                                  this.props.logUserAction('SHARE', {custom_var_8: 'whatsapp', custom_var_9: this.state.shareSelection, custom_var_10: this.getShareLink()});
                              }} />
                          </div>
                      }
                    </div>
                    <div className="closeDiv">
                        <button className="genericButton shareButton closeButton" onClick={this.closeModal}>Close</button>
                    </div>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    shareModalVisible: state.ui.shareModalVisible,
    transcriptionVisible: state.ui.transcriptionVisible,
    activeSegment: state.runningOrder.active.segment,
    activeTopic: state.runningOrder.active.topic,
    activeVersion: state.runningOrder.active.version
});

const mapDispatchToProps = dispatch => ({
    toggleShareModalVisible: ()=> dispatch(toggleShareModalVisible()),
    showSnackbar: (message)=> dispatch(showSnackbar(message)),
    hideSnackbar: ()=> dispatch(hideSnackbar()),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
