import React, {Component} from 'react'


import './style.css'
import { connect } from 'react-redux';

import {
    updateContributorVisible,
    logUserAction
} from '../../../../modules/ui';

class Contributor extends Component {


    render() {
        return (
            <div className="contributor_card" onClick={this.clickedContributor.bind(this)}>
                <div>
                    <img alt={this.props.contributor.name} className="contributor_profile_picture" src={`/images/thumbnails/${this.props.contributor.thumbnail?this.props.contributor.thumbnail:'man-user.svg'}`} />
                </div>
                <div className="contributor_card_text">
                    <span className="gel-pica">{this.props.contributor.name}</span>
                    <span className="gel-pica">{this.props.contributor.title}</span>
                    {
                       this.props.contributor.additional_link && 
                       <a rel="noopener noreferrer" href={this.props.contributor.additional_link.url} target="_blank"><span>{this.props.contributor.additional_link.caption}</span></a>
                    }
                    
                </div>
                {/* <div>
                    <Info />
                </div>   */}
            </div>
        )
    }

    clickedContributor() {
        this.props.logUserAction('CONTRIBUTOR_MODAL_OPEN',{custom_var_8: this.props.contributor._id, custom_var_9: this.props.contributor.name, custom_var_10: 'card'});
        this.props.updateContributorVisible(this.props.contributor);
    }
}


const mapStateToProps = state => ({
});

   const mapDispatchToProps = dispatch => ({
    updateContributorVisible: (contributor) => dispatch(updateContributorVisible(contributor)),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
   })

   export default connect(mapStateToProps, mapDispatchToProps)(Contributor);

//export default Timeline
