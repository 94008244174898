import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';

import Modal from 'react-modal';

import {
    updateContributorVisible,
    logUserAction
} from '../../../modules/ui'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex:  500000000,
    borderRadius: '0'
  }
};

document.addEventListener('contextmenu', event => event.preventDefault());
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.9)';
Modal.defaultStyles.overlay.zIndex = 899898989;


class ContributorModal extends Component {


    constructor(props) {
        super(props);
    
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }

    afterOpenModal() {
    }
    
    closeModal() {
        this.props.logUserAction('CONTRIBUTOR_MODAL_CLOSE',{custom_var_8: this.props.contributorVisible._id, custom_var_9: this.props.contributorVisible.name});
        this.props.updateContributorVisible(false);
    }

    render() {
        return (
            <Modal
                isOpen={this.props.contributorVisible!==false}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >

                {
                    this.props.contributorVisible &&
                    <div className="contributorModal">
                        <div className="contributorInfo">
                          <div>
                              <img alt={this.props.contributorVisible.name} className="contributor_large_profile_picture" src={`/images/thumbnails/${this.props.contributorVisible.thumbnail?this.props.contributorVisible.thumbnail:'man-user.svg'}`} />
                          </div>
                          <h2>{this.props.contributorVisible.name}</h2>
                          <p>{this.props.contributorVisible.role}</p>
                          <p>{this.props.contributorVisible.title}</p>
                          {
                              this.props.contributorVisible.external_link &&
                              <p><a rel="noopener noreferrer" href={this.props.contributorVisible.external_link.url} target="_blank" onClick={()=>{
                                  this.props.logUserAction('CONTRIBUTOR_LINK', {custom_var_8: this.props.contributorVisible._id, custom_var_9: this.props.contributorVisible.external_link.url})
                              }}><span>{this.props.contributorVisible.external_link.caption}</span></a></p>
                          }
                        </div>
                        <div className="closeDiv">
                            <button onClick={this.closeModal} className="genericButton">Close</button>
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    contributorVisible: state.ui.contributorVisible
});

const mapDispatchToProps = dispatch => ({
    updateContributorVisible: (contributor)=> dispatch(updateContributorVisible(contributor)),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContributorModal);
