import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';

import {
    showSnackbar,
    hideSnackbar
} from '../../../modules/ui';

class Snackbar extends Component {

  componentDidUpdate(prevProps) {

      if (this.props.snackbarMessage !== prevProps.snackbarMessage &&
          this.props.snackbarMessage !== "")
      {
          setTimeout(this.props.hideSnackbar, 3000);
      }
  }

    render() {
        return (
            <div className={`snackbar ${this.props.snackbarMessage.length?'show':''}`}>{this.props.snackbarMessage}</div>
        )
    }
}

const mapStateToProps = state => ({
    snackbarMessage: state.ui.snackbarMessage
});

const mapDispatchToProps = dispatch => ({
   showSnackbar: (message) => dispatch(showSnackbar(message)),
   hideSnackbar: () => dispatch(hideSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
