class EchoLogger {
    constructor() {
        this.sessionID = `${localStorage.participantID}_${localStorage.location}`;
        this.sessionStartTime = Date.now();
    }
    async init() {
        return new Promise((done) => {
            window.requirejs(['echo'], (Echo) => {
                Echo.Debug.enable();

                var conf = { };
                conf[Echo.ConfigKeys.ATI_ENABLED] = 'true';
                conf[Echo.ConfigKeys.DESTINATION] = Echo.Enums.Destinations.TASTER;

                this.echo = new Echo.EchoClient(
                    'taster',
                    Echo.Enums.ApplicationType.WEB,
                    conf
                );
                this.echo.addManagedLabel(Echo.Enums.ManagedLabels.BBC_SITE, 'taster');
                this.echo.addLabel('pilot_name', 'even-more-or-less-usertesting');
                this.echo.setAppVersion('0.0.1');
                this.echo.viewEvent('taster.pilot.even-more-or-less-usertesting.page', {
                    section: `even-more-or-less-usertesting::${this.sessionID}`
                });

                done();
            });
        });
    }

    async log(name, labels) {
        //TODO Add session info here?
        await this.init();

        const data = Object.assign(labels, {
            section: `even-more-or-less-usertesting::${this.sessionID}`,
            custom_var_1: name
        });
        this.echo.viewEvent('taster.pilot.even-more-or-less-usertesting.page', data);
    }

    uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    }

    getMetadata(state) {
        const sessionTime = (Date.now() - this.sessionStartTime) / 1000;
        return {
            custom_var_2: sessionTime,
            custom_var_3: state.playback.currentTimestamp, // current_timestamp
            custom_var_4: state.playback.playing, // playing
            custom_var_5: state.runningOrder.active.segment ? state.runningOrder.active.segment._id : null, // active_segment
            custom_var_6: state.ui.topicsVisible, // topics_visible
            custom_var_7: state.ui.transcriptionVisible // transcription_visible
        }
    }
}

// global instance of the echo logger
const logger = new EchoLogger();

export const logUserAction = (action, prevState, nextState) => {
    const data = Object.assign(logger.getMetadata(nextState), action.payload);
    return {
        type: 'user_action',
        name: action.action_type,
        data
    }
}

export const logSystemAction = (action, prevState, nextState) => {
    let action_type = action.type;
    if (action.type === 'QUEUE_COMMAND') action_type = action.payload.command;
    return {
        type: 'system_action',
        name: `SYS_${action_type}`,
        data: logger.getMetadata(nextState)
    }
}

export const echoTarget = (events) => {
    events.forEach(event => {
        logger.log(event.name, event.data);
    });
}
