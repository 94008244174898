const initialState = {
    pendingCommand: null,
    currentTimestamp: 0,
    pendingTimestamp: null,
    started: false,
    playing: false,
    totalDuration: 0,
    initialised: false,
    buffering: true,
    readyToPlay: false
  }
  
  export default (state = initialState, action) => {
      switch (action.type) {
       case 'QUEUE_COMMAND':
        return Object.assign({}, state, {
          pendingCommand: action.payload
        });
        case 'RESET_PENDING_COMMAND':
        return Object.assign({}, state, {
          pendingCommand: action.payload
        });
        case 'UPDATE_CURRENT_TIMESTAMP':
        return Object.assign({}, state, {
          currentTimestamp: action.payload
        });
        case 'UPDATE_PLAYING_STATE':
        return Object.assign({}, state, {
          playing: action.payload
        });
        case 'UPDATE_BUFFERING_STATE':
        return Object.assign({}, state, {
          buffering: action.payload
        });
        case 'INITIALISED':
            return Object.assign({}, state, {
                initialised: true
            });
        case 'READY_TO_PLAY':
            return Object.assign({}, state, {
                readyToPlay: true
            })
       default:
        return state
      }
     }
  
export const playAction = () => dispatch => {
    dispatch({
        type: 'QUEUE_COMMAND',
        payload: {
            type: 'TRANSPORT',
            command: 'PLAY'
        }
    });
}

export const pauseAction = () => dispatch => {
    dispatch({
        type: 'QUEUE_COMMAND',
        payload: {
            type: 'TRANSPORT',
            command: 'PAUSE'
        }
    });
}

export const initialisedAction = () => dispatch => {
    dispatch({
        type: 'INITIALISED',
    });
}

export const readyToPlayAction = () => dispatch => {
    dispatch({
        type: 'READY_TO_PLAY',
    });
}

export const seekAction = (timestamp, segmentID) => dispatch => {
    dispatch({
        type: 'QUEUE_COMMAND',
        payload: {
            type: 'TRANSPORT',
            command: 'SEEK',
            timestamp: timestamp,
            segment: segmentID
        }
    });
}

export const loadPidAction = (pid) => dispatch => {
    dispatch({
        type: 'QUEUE_COMMAND',
        payload: {
            type: 'PLAYLIST',
            command: 'LOAD_PID',
            pid
        }
    });
}

export const loadUrlAction = (url) => dispatch => {
    dispatch({
        type: 'QUEUE_COMMAND',
        payload: {
            type: 'PLAYLIST',
            command: 'LOAD_URL',
            url
        }
    });
}

export const seekPercentageAction = (percentage) => dispatch => {
    dispatch({
        type: 'QUEUE_COMMAND',
        payload: {
            type: 'TRANSPORT',
            command: 'SEEK_PERCENTAGE',
            percentage
        }
    });
}


export const resetPendingCommandAction = () => dispatch => {
    dispatch({
        type: 'RESET_PENDING_COMMAND',
        payload: null
    });
}

export const updateCurrentTimestampAction = (timestamp) => dispatch => {
    dispatch({
        type: 'UPDATE_CURRENT_TIMESTAMP',
        payload: timestamp
    });
}

export const updatePlayingStateAction = (bool) => dispatch => {
    dispatch({
        type: 'UPDATE_PLAYING_STATE',
        payload: bool
    });
}

export const updateBufferingStateAction = (bool) => dispatch => {
    dispatch({
        type: 'UPDATE_BUFFERING_STATE',
        payload: bool
    });
}
    
       