import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';
import Modal from 'react-modal';
import moment from 'moment';

import {
    toggleShareEntryModalVisible,
    logUserAction
} from '../../../modules/ui'
import {
    seekAction,
    playAction
} from '../../../modules/playback'

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    borderRadius:0
  }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.6)';
Modal.defaultStyles.overlay.zIndex = 99999999999999999999999999;


class ShareEntryModal extends Component {

    constructor(props) {
        super(props);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    afterOpenModal() {
    }
    
    closeModal() {
        this.props.toggleShareEntryModalVisible();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.shareEntryModalVisible}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Help"
                >
                <div className="shareEntryModal">

                    <p>You have been sent a link to this podcast to start from a specific time.</p>
                    <div>
                        { this.props.initialised &&
                        <button onClick={()=>{
                            this.props.logUserAction('SHARE_LINK_JUMP');
                            // window.smpPlayer.player.captureIntent();
                            this.props.playAction()
                            this.closeModal();
                        }} className={`genericButton active`}>Play from {moment.utc(window.toSeekTo *1000).format('HH:mm:ss')}</button>
                        }
                    </div>
                </div>
                
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    shareEntryModalVisible: state.ui.shareEntryModalVisible,
    initialised: state.playback.initialised
});

const mapDispatchToProps = dispatch => ({
    toggleShareEntryModalVisible: ()=> dispatch(toggleShareEntryModalVisible()),
    seekAction: (timestamp, segmentID)=> dispatch(seekAction(timestamp, segmentID)),
    playAction: ()=> dispatch(playAction()),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareEntryModal);
