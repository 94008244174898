import React, {Component} from 'react'
import { Play, Pause, Loading } from '@bbc/gel-react-webpack/iconography'

import './style.css'
import { connect } from 'react-redux';
import {
    playAction,
    pauseAction
} from '../../../modules/playback';
import {
    logUserAction
} from '../../../modules/ui'

class TransportControls extends Component {


    sec2time(timeInSeconds) {
        var pad = function(num, size) { return ('000' + num).slice(size * -1); },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60);
        //milliseconds = time.slice(-3);

        //return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    }

    playPause() {
        if (!this.props.playing) {
            window.noSleep.enable();
            this.props.logUserAction('PLAY');
            // window.smpPlayer.player.play();
            this.props.playAction();
        } else {
            window.noSleep.disable();
            this.props.logUserAction('PAUSE');
            this.props.pauseAction();
        }

    }

    getTransportButton() {
        if (this.props.buffering || !this.props.initialised || !this.props.readyToPlay) {
            return <div className="transportIcon">
                    <Loading />
                </div>
        } else {
            if (this.props.playing) {
                return <div onClick={this.playPause.bind(this)} className="transportIcon">
                    <Pause />
                </div>
            } else {
                return <div onClick={this.playPause.bind(this)} className="transportIcon">
                    <Play />
                </div>
            }
        }
        
    }

    render() {
        return (
            <div className="transportControls">
                <div style={{
                    borderColor:`${this.props.primaryTheme}`,
                    color: this.props.primaryTheme
                }} className="transportButton">
                    {this.getTransportButton()}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    buffering: state.playback.buffering,
    initialised: state.playback.initialised,
    readyToPlay: state.playback.readyToPlay,
    playing: state.playback.playing,
    primaryTheme: state.ui.theme.primary
   });

   const mapDispatchToProps = dispatch => ({
    playAction: () => dispatch(playAction()),
    pauseAction: () => dispatch(pauseAction()),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
   })

   export default connect(mapStateToProps, mapDispatchToProps)(TransportControls);

//export default Timeline
