import React, {Component} from 'react';
import { Share, Feedback, Chapters, Help, Loading } from '@bbc/gel-react-webpack/iconography';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import './style.css';
import NoSleep from 'nosleep.js'

import Timeline from './timeline';
import TimeDisplay from './timeDisplay';
import TransportControls from './transportControls';
import Seekbar from './seekbar';
import SmpWrapper from './smpWrapper';
import ShareModal from './shareModal';
import ShareEntryModal from './shareEntryModal';
import HelpModal from './helpModal';
import CookieModal from './cookieModal';
import ContributorModal from './contributorModal';
import Snackbar from './snackbar';
import {
    fetchRunningOrder
} from '../../modules/runningOrder';
import {
    toggleTranscriptionVisible,
    toggleTopicsVisible,
    toggleShareModalVisible,
    showCookieModal,
    toggleHelpModalVisible,
    logUserAction
} from '../../modules/ui';

import {
    loadPidAction
} from '../../modules/playback';

class Play extends Component {

    constructor(props) {
        super(props);
        window.noSleep = new NoSleep();
    }

    async componentDidMount() {
        this.props.showCookieModal();
        // if (this.props.match.params.version_id) {
        // await this.props.fetchRunningOrder("p06pbpb2");
        // await this.props.fetchRunningOrder("m000204h");
        // } else {
        //     //show 404 - redirect
        // }
    }

    getPopulatedTopics() {
        return this.props.currentRunningOrder.getPopulatedTopics();
    }

    getPlayLayout() {
            return (
                <div className="play-container noselect bbc-font-v2">
                    <div style={{background: this.props.primaryTheme}} className="play-top">
                        <div  className="play-top-branding">
                            <img alt="BBC Logo" className="brandingLogo" src="/images/bbcLogoSimple.svg" />
                        </div>
                        <div className="play-top-user-buttons">
                            <div onClick={this.props.toggleHelpModalVisible} className="play-top-user-button">
                                <Help />
                            </div>
                            <div onClick={this.props.toggleShareModalVisible} className="play-top-user-button">
                                <Share />
                            </div>
                            <div onClick={()=>{
                                this.props.logUserAction(this.props.transcriptionVisible?'TRANSCRIPTS_OFF':'TRANSCRIPTS_ON');
                                this.props.toggleTranscriptionVisible();
                            }} style={{color:this.props.transcriptionVisible? 'white': 'rgba(255,255,255,0.6)'}} className="play-top-user-button">
                                <Feedback />
                            </div>
                            <div  onClick={()=>{
                                this.props.logUserAction(this.props.topicsVisible?'SEEKBAR_CLOSE':'SEEKBAR_OPEN', {custom_var_8: 'button'});
                                this.props.toggleTopicsVisible(!this.props.topicsVisible);
                            }} style={{
                                color:this.props.topicsVisible? 'white': 'rgba(255,255,255,0.6)'
                            }} className="play-top-user-button">
                                <Chapters />
                            </div>
                            
                        </div>
                    </div>
                    {
                        this.props.currentRunningOrder &&
                        <div className="play-middle">
                            <div className="play-middle-timeline">
                                <Route exact path={`${this.props.match.path}`} component={Timeline} />
                            </div>
                            <div style={{backgroundColor: `${this.props.topicsVisible ? 'rgba(255,255,255,0.95)': 'rgba(255,255,255,0)'}`}} className="play-middle-seekbar">
                                <Seekbar />
                                <TimeDisplay />
                                <TransportControls />
                            </div>
                        <SmpWrapper />
                        <HelpModal />
                        <ShareModal />
                        <ShareEntryModal />
                        
                        <ContributorModal />
                        </div>
                        
                    }
                    {
                        !this.props.currentRunningOrder &&
                        <div className="play-middle">
                            <div className="loadingEpisodeHolder">
                            <h3>Loading podcast, please wait...</h3>
                            <Loading />
                            </div>
                            
                        </div>
                    }

                <CookieModal />
                <Snackbar />
                </div>
            );
    }

    render() {
        return this.getPlayLayout();
    }
}

const mapStateToProps = state => ({
    currentRunningOrder: state.runningOrder.currentRunningOrder,
    primaryTheme: state.ui.theme.primary,
    transcriptionVisible: state.ui.transcriptionVisible,
    topicsVisible: state.ui.topicsVisible
});
  
const mapDispatchToProps = dispatch => ({
    fetchRunningOrder: (pid) => dispatch(fetchRunningOrder(pid)),
    toggleTranscriptionVisible: () => dispatch(toggleTranscriptionVisible()),
    toggleTopicsVisible: (bool) => dispatch(toggleTopicsVisible(bool)),
    toggleShareModalVisible: () => dispatch(toggleShareModalVisible()),
    toggleHelpModalVisible: () => dispatch(toggleHelpModalVisible()),
    showCookieModal: () => dispatch(showCookieModal()),
    loadPidAction: (url) => dispatch(loadPidAction(url)),
    logUserAction: (action_type, payload) => dispatch(logUserAction(action_type, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Play);