import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app';


import 'sanitize.css/sanitize.css'
import './index.css'

import * as serviceWorker from './serviceWorker';
console.log(`%c ...so you'd like to see how this works? This application was developed for the BBC by thomas@creativelycommon.co.uk`, 'background: #252526; color: #ffffff');
const target = document.querySelector('#root')

if (typeof Object.assign != 'function' ||
    !Array.prototype.map ||
    !Array.prototype.includes)
{
    alert('Please upgrade to a recent version of Chrome, Firefox, Edge, Safari or Opera to experience this prototype.');
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
);

if (module.hot) {
  // becuase he had sexy peel.
  module.hot.accept('./containers/app', () => {
    const NextApp = require('./containers/app').default;
    render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <NextApp />
          </div>
        </ConnectedRouter>
      </Provider>,
      target
    );
  });
}

serviceWorker.unregister();