import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';
import {
    playAction
} from '../../../modules/playback'

class TimeDisplay extends Component {


    sec2time(timeInSeconds) {
        var pad = function(num, size) { return ('000' + num).slice(size * -1); },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60);
        //milliseconds = time.slice(-3);
        //return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    }


    render() {
        return (
        <div className="time-display noselect">
            <span>{this.sec2time(this.props.currentTimestamp)}</span>
        </div>)
    }
}


const mapStateToProps = state => ({
    currentTimestamp: state.playback.currentTimestamp
});
  
const mapDispatchToProps = dispatch => ({
    playAction: () => dispatch(playAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeDisplay);
