import React, {Component} from 'react'
import './style.css'
import { connect } from 'react-redux';
import Modal from 'react-modal';

import {
    showCookieModal,
    hideCookieModal
} from '../../../modules/ui';
import {
    fetchRunningOrder,
    setFetching,
    setFetchingError
} from '../../../modules/runningOrder';

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    borderRadius:0
  }
};

document.addEventListener('contextmenu', event => event.preventDefault());
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.8)';
Modal.defaultStyles.overlay.zIndex = 99999999999999999999999999;


class CookieModal extends Component {

    constructor(props) {
        super(props);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loading = false;
    }

    afterOpenModal() {
    }

    loadData() {
        this.loading = true;
        let participantID = document.getElementById('participantID').value;
        let location = document.getElementById('location').value;
        localStorage.participantID = participantID;
        localStorage.location = location;
        if (participantID === 'demo') {
            this.props.fetchRunningOrder('demo');
        } else {
            this.props.fetchRunningOrder(`${participantID}_${location}`);
        }
    }

    closeModal() {
        this.props.hideCookieModal();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.cookieModalVisible}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
                contentLabel="Help"
                >
                <div className="cookieModal">
                    <div className="cookieText">
                      <h3>Welcome</h3>
                      <p>Thank you for participating in this experiment. Firstly, please ensure you are connected to WiFi so you can download the podcast. Enter your participant ID and location below, then click next to continue.</p>
                    </div>
                    {this.props.fetching_error != null &&
                        <div className="closeDiv">
                            <span id="error" className="red">{this.props.fetching_error}</span>
                        </div>
                    }
                    {!this.props.fetching && !this.props.buffering && this.loading &&
                        <div className="closeDiv">
                            <span id="downloading2" className="green">Data downloaded. Ready to start.</span><br />
                            <button className="agreeButton" onClick={this.closeModal}>Start</button>
                        </div>
                    }
                    {(this.props.fetching || this.props.buffering) && this.props.fetching_error === null && this.loading &&
                        <div className="closeDiv">
                            <span id="downloading" className="red">Downloading data, please wait...</span>
                        </div>
                    }
                    {!this.loading &&
                        <div className="closeDiv">
                        <label>Participant ID:</label><select id="participantID">
                            <option value="n/a">Select...</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="demo">demo</option>
                        </select>
                        <br />
                        <label>Location:</label><select id="location">
                            <option value="n/a">Select...</option>
                            <option value="home">Home</option>
                            <option value="work">Work/study</option>
                            <option value="travel">Commute/travel</option>
                        </select>
                        <button className="genericButton" onClick={this.loadData}>Next</button>
                        </div>
                    }
                </div>

            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    cookieModalVisible: state.ui.cookieModalVisible,
    buffering: state.playback.buffering,
    fetching: state.runningOrder.fetching,
    fetching_error: state.runningOrder.fetching_error
});

const mapDispatchToProps = dispatch => ({
    fetchRunningOrder: (pid) => dispatch(fetchRunningOrder(pid)),
    showCookieModal: ()=> dispatch(showCookieModal()),
    hideCookieModal: ()=> dispatch(hideCookieModal()),
    setFetching: (status)=> dispatch(setFetchingError(status)),
    setFetchingError: (error)=> dispatch(setFetchingError(error))
})

export default connect(mapStateToProps, mapDispatchToProps)(CookieModal);
